<template>
  <div class="delivery-info" t-id="delivery-info">
    <StepItem
      data-testid="address-step"
      :heading="t('DELIVERY_ADDRESS.HEADING')"
      :step="Step.DeliveryAddress"
      :active="getActiveStep(Step.DeliveryAddress)"
    >
      <DeliveryAddress
        :loading-address="loadingAddress"
        :active="getActiveStep(Step.DeliveryAddress)"
        :isEditing="isEditing(Step.DeliveryAddress)"
        :address="legalAddress"
        :default-address="defaultAddress"
        :scope-id="scopeId"
        :show-package-receiver="shouldShowDeliveryForPackage"
        :show-emn-delivery-info="shouldShowEmnDeliveryInfo"
        :activated-subscriptions-icc="activatedSubsByICC"
        :subscription-deliveries="physicalSubscriptionDelivery"
        @handleSelectAddress="handleSelectAddress"
        @completeStep="handleNextStep"
        @handleEditAddress="handleEditStep(Step.DeliveryAddress)"
      />
    </StepItem>
    <StepItem
      data-testid="contact-step"
      :heading="t('DELIVERY_CONTACT_INFO.HEADING')"
      :step="Step.DeliveryContactInfo"
      :active="getActiveStep(Step.DeliveryContactInfo)"
    >
      <DeliveryContactInfo
        :active="getActiveStep(Step.DeliveryContactInfo)"
        :is-editing="isEditing(Step.DeliveryContactInfo)"
        @completeStep="handleNextStep"
        @handleEditContact="handleEditStep(Step.DeliveryContactInfo)"
      />
    </StepItem>
    <StepItem
      :heading="t('INVOICE_ACCOUNT.HEADING')"
      :step="Step.InvoiceAccount"
      :active="getActiveStep(Step.InvoiceAccount)"
    >
      <InvoiceAccount :active="getActiveStep(Step.InvoiceAccount)" />
    </StepItem>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../../locale";
import DeliveryAddress from "./DeliveryAddress.vue";
import InvoiceAccount from "../invoice/InvoiceAccount.vue";
import DeliveryContactInfo from "./DeliveryContactInfo.vue";
import StepItem from "../ui-components/StepItem.vue";
import { useBasketStore, useStepperStore } from "../../store";
import { onMounted, ref, toRefs } from "vue";
import { LegalAddressStructuredSuggestionDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";

import { Delivery, Step } from "../../data-types";
import { storeToRefs } from "pinia";
import "@telia/b2b-address-search";
import "@telia/b2b-autocomplete-input";
import { getAddress } from "../../service/addressService";

interface Props {
  scopeId: string;
  tscid: string;
}

const t = translateMixin.methods.t;
const props = defineProps<Props>();

const stepperStore = useStepperStore();
const { editStep, currentStep, isEditing } = storeToRefs(stepperStore);
const { setEditStep, unsetEditStep, nextStep } = stepperStore;

const basketStore = useBasketStore();
const {
  shouldShowDeliveryForPackage,
  physicalSubscriptionDelivery,
  shouldShowEmnDeliveryInfo,
  activatedSubsByICC,
} = storeToRefs(basketStore);

const legalAddress = ref<Delivery>({
  businessName: "",
  attention: "",
  street: "",
  streetNumber: "",
  postalCode: "",
  city: "",
  entrance: "",
});
const defaultAddress = ref<LegalAddressStructuredSuggestionDTO>();
const loadingAddress = ref(true);

onMounted(async () => {
  try {
    const response = await getAddress(props.scopeId, props.tscid);
    legalAddress.value = {
      businessName: response?.organizationName ?? "",
      attention: "",
      street: response?.structuredAddressSuggestions?.[0]?.streetName ?? "",
      streetNumber: response?.structuredAddressSuggestions?.[0]?.streetNumber ?? "",
      postalCode: response?.structuredAddressSuggestions?.[0]?.postalCode ?? "",
      city: response?.structuredAddressSuggestions?.[0]?.city ?? "",
      entrance: response?.structuredAddressSuggestions?.[0]?.entrance ?? "",
    };
    if (response.structuredAddressSuggestions?.length) {
      defaultAddress.value = response?.structuredAddressSuggestions?.[0];
    } else if (response.address && response.postalCode && response.city) {
      defaultAddress.value = {
        streetName: response.address,
        postalCode: response.postalCode,
        city: response.city,
        fullAddress: `${response?.address}, ${response.postalCode} ${response.city}`,
      };
    }
  } catch (e) {
    console.log(e);
  } finally {
    loadingAddress.value = false;
  }
});

function handleNextStep() {
  if (editStep.value) {
    unsetEditStep();
    return;
  }
  nextStep();
}
function getActiveStep(step: number) {
  return editStep.value ? editStep.value === step : currentStep.value === step;
}
function handleEditStep(step: number) {
  setEditStep(step);
}
function handleSelectAddress(address: Delivery) {
  legalAddress.value = address;
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.delivery-info {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-32;
}
</style>
