<template>
  <div
    class="b2b-order-products-checkout"
    t-id="b2b-order-products-checkout"
    data-test-id="b2b-order-products-checkout"
  >
    <b2b-layout
      :heading="t('HEADING.CHECK_OUT')"
      show-back-link
      :back-link-label="t('HEADING.CONTINUE_SHOPPING')"
      :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
      show-organisation-selector
      :selected-organisation="tscid"
      :organisation-selector-disabled="true"
    >
      <span v-if="customers && customers.length > 1" slot="agreement-selector">
        <telia-grid>
          <telia-p>{{ selectedCustomer?.name }} </telia-p>
        </telia-grid>
      </span>
      <telia-grid v-if="errorHandlingStore?.setupError">
        <Notification
          :status="errorHandlingStore.setupError?.status"
          :heading="t('ERROR.HEADING')"
          :body="errorHandlingStore.setupError?.message"
        />
      </telia-grid>
      <telia-grid v-else-if="isEmptyBasket">
        <telia-row>
          <telia-col width="12">
            <div class="b2b-order-products-checkout__empty-basket">
              <telia-heading variant="title-200" tag="h2">
                {{ t("CART.EMPTY_BASKET") }}
              </telia-heading>
              <img :src="EmptyBasket" alt="empty basket" />
            </div>
          </telia-col>
        </telia-row>
      </telia-grid>
      <telia-grid>
        <telia-row v-if="render && !errorHandlingStore.setupError && !isEmptyBasket">
          <telia-col width-lg="7" width="12">
            <DeliveryInfo :scope-id="scopeId" :tscid="tscid" />
          </telia-col>
          <telia-col width-lg="5" width="12">
            <Cart
              v-if="basket"
              :scope-id="scopeId"
              :tscid="tscid"
              :basket="basket"
              :is-admin="isAdmin"
            />
          </telia-col>
        </telia-row>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "./locale";
import { currentLocale } from "@telia/b2b-i18n";
import { ref, onMounted, computed } from "vue";
import {
  corpMobileCustomerService,
  corpProductOrder,
  corpAgreements,
  corpNumberBooking,
} from "@telia/b2b-rest-client";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import DeliveryInfo from "./components/delivery-configuration/DeliveryInfo.vue";
import Cart from "./components/cart/Cart.vue";
import { getTscidFromUrl } from "./helpers/urlParams";
import { BasketUI, Error } from "./data-types";
import EmptyBasket from "./assets/empty_basket_img.svg";

import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { useUserConfigStore, useBasketStore, useErrorHandlingStore } from "./store";
import Notification from "./components/ui-components/Notification.vue";
import { storeToRefs } from "pinia";
translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const scopeId = ref<string>("");
const tscid = ref<string>("");
const customers = ref<corpMobileCustomerService.MobileUser[]>();
const basket = ref<BasketUI>();
const render = ref<boolean>(false);
const basketStore = useBasketStore();
const { isEmptyBasket } = storeToRefs(basketStore);
const userConfigStore = useUserConfigStore();
const errorHandlingStore = useErrorHandlingStore();
const isAdmin = ref(false);

const selectedCustomer = computed(() => {
  if (!customers.value || !basket.value) return undefined;

  return customers.value.find(
    (customer) => customer.customerNumber === basket.value?.customerNumber
  );
});

onMounted(() => {
  setup();
});

async function setup() {
  try {
    scopeId.value = await getScopeIdOrThrow();
    userConfigStore.setScopeId(scopeId.value);
  } catch {
    handleError({ message: t("ERROR.USER_INFORMATION"), status: "warning" });
  }
  tscid.value = getTscidFromUrl();
  userConfigStore.setTscid(tscid.value);

  isTeliaAdmin()
    .then((response) => {
      isAdmin.value = response;
    })
    .catch(() => {
      // assume not admin, no error
    });

  try {
    let hasNfa = false;
    let isMfa = false;
    corpAgreements.NfAgreementsControllerService.getNfAgreementsOnScope(scopeId.value, [
      tscid.value,
    ]).then((response) => {
      hasNfa = response.agreements?.some((agreement) => agreement.nfAgreementNumber) ?? false;
      userConfigStore.setHasNfa(hasNfa);
      isMfa = response.agreements?.some((agreement) => agreement.agreementType === "SME") ?? false;
      userConfigStore.setIsMfa(isMfa);
    });
  } catch (e) {}

  try {
    const response =
      await corpMobileCustomerService.MobileCustomerControllerService.getMobileCustomer(
        scopeId.value,
        { tscId: tscid.value }
      );
    if (!response?.results) return;
    customers.value = response.results;
  } catch {
    handleError({ message: t("ERROR.USER_INFORMATION"), status: "warning" });
  }
  try {
    const lang = currentLocale() === "SV_SE" ? "SV" : "EN";
    const response = await corpProductOrder.BasketControllerService.getBasket(
      scopeId.value,
      tscid.value,
      lang
    );
    basket.value = response;
    basketStore.setBasket(response);
    let customer;
    if (!customers.value) return;
    customer = customers.value.find(
      (customer) => customer.customerNumber === basket.value?.customerNumber
    );

    if (!customer) {
      customer = customers.value[0];
    }
    userConfigStore.setSelectedCustomer(customer);
  } catch {
    handleError({ message: t("ERROR.BASKET"), status: "warning" });
  }
  try {
    await basket.value?.lines.forEach((sub) => {
      const exclude = excludeNumberReservationCheckForLine(sub);
      if (exclude) return;

      corpNumberBooking.PublicNumberBookingControllerService.reservedNumberStatus(
        scopeId.value,
        tscid.value,
        sub.msisdn
      ).then((reservedNumber) => {
        if (reservedNumber.status !== "FREE" && reservedNumber.status !== "BOOKED") {
          basketStore.setUnavailbleSubscriptions(reservedNumber.msisdn);
        }
        if (reservedNumber.status === "BOOKED" && reservedNumber.msisdn) {
          basketStore.addBookedNumber(reservedNumber.msisdn);
        }
      });
    });
  } catch (e) {
    console.log(e);
  }

  render.value = true;
}
function handleError(error: Error) {
  errorHandlingStore.setError(error);
}
function excludeNumberReservationCheckForLine(line) {
  const matchingTransferLine = (relationId) => {
    return basket.value?.processLines?.find(
      (l) => l.relationId === relationId && l.product?.subCategory === "transferofownership"
    );
  };
  return line.product?.operation === "EXTEND_SUBSCRIPTION" || matchingTransferLine(line.relationId);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.b2b-order-products-checkout {
  height: 100%;
  background-color: $telia-gray-50;
  &__empty-basket {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-48;

    img {
      width: 30%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
